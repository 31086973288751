$(document).ready(function () {
   //anime on scroll
   var windowWidht = $(window).width();
   if (windowWidht > 1240) {
    var waypoint0 = new Waypoint({
        element: document.getElementById('projects'),
        handler: function handler(direction) {
            
            this.destroy();
            var timelineProj = anime.timeline();
            timelineProj.add({
                targets: '.line-top',
                height: ['0', '188'],
                duration: 300,
                elasticity: 0,
                easing: 'easeInQuad'
            }).add({
                targets: '.line-left',
                width: ['0', '400'],
                duration: 300,
                elasticity: 0,
                easing: 'easeInQuad'
            }).add({
                targets: ['#dot-3'],
                opacity: [0, 1],
                duration: 500,
                easing: 'easeOutExpo',

                offset: '-=200'
            }).add({
                targets: ['#dot-3'],
                borderWidth: ['0px', '3px'],
                boxShadow: ['0px 0px 0 0px rgba(255, 247, 148, 0.45)', '0px 0px 0 4px rgba(255, 247, 148, 0.45)'],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=300'
            }).add({
                targets: ['.project_an'],
                translateY: ['2000', '0'],
                duration: 700,
                easing: 'easeOutExpo',
                offset: '-=800'
            }).add({
                targets: ['.title-1_an'],
                opacity: ['0', '1'],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=700'
            }).add({
                targets: '.line-right',
                width: ['0', '451'],

                translateX: ['-451', '0'],
                duration: 300,
                elasticity: 0,
                easing: 'easeInQuad'
            }).add({
                targets: '.line-bottom',
                bottom: ['2000', '0'],
                duration: 400,
                elasticity: 0,
                easing: 'easeInQuad'
            });
        },

        offset: '80%'
    });
    var waypoint1 = new Waypoint({
        element: document.getElementById('design'),
        handler: function handler(direction) {
            
            this.destroy();
            var timelineDesign = anime.timeline();
            var numAnimate = {
                prop1: 0,
                prop2: 0,
                prop3: 0,
                prop4: 0
            };
            timelineDesign.add({
                targets: '.line-des-top',
                height: ['0', '200'],
                duration: 300,
                elasticity: 0,
                easing: 'easeInQuad'
            }).add({
                targets: '.line-des-right',
                width: ['0', '411'],
                duration: 300,
                elasticity: 0,
                easing: 'easeInQuad'
            }).add({
                targets: ['#dot-4'],
                opacity: [0, 1],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=200'
            }).add({
                targets: ['#dot-4'],
                borderWidth: ['0px', '3px'],
                boxShadow: ['0px 0px 0 0px rgba(255, 247, 148, 0.45)', '0px 0px 0 4px rgba(255, 247, 148, 0.45)'],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=300'
            }).add({
                targets: ['.title-2_an'],
                opacity: ['0', '1'],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=300'
            }).add({
                targets: '.design-card',
                opacity: ['0', '1'],
                translateX: ['-300', '0'],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=500',
                delay: function delay(el, i, l) {
                    return i * 200;
                }
            }).add({
                targets: ['.design-title', '.design-desc'],
                translateY: ['-10', '0'],
                opacity: ['0', '1'],
                duration: 1000,
                easing: 'easeOutExpo',
                offset: '-=800',
                delay: function delay(el, i, l) {
                    return i * 80;
                }
            }).add({
                targets: ['.design-num'],
                translateY: ['-10', '0'],
                opacity: ['0', '0.3'],
                duration: 300,
                easing: 'easeOutExpo',
                offset: '-=1000',
                delay: function delay(el, i, l) {
                    return i * 80;
                }

            }).add({
                duration: 600,
                easing: 'easeOutExpo',
                targets: numAnimate,
                prop1: 1,
                prop2: 2,
                prop3: 3,
                prop4: 4,
                round: 1,
                update: function update(el) {
                    var i = 1;
                    for (i; i < 5; i++) {
                        $('.design-card:nth-child(' + i + ') .design-num').text('0' + numAnimate['prop' + i]);
                    }
                },
                offset: '-=800'
            }).add({
                targets: ['.dotted-line-hor', '.dotted-line-hor-left'],
                width: ['0px', '364px'],
                duration: 500,
                easing: 'linear',
                offset: '-=1500',
                delay: function delay(el, i, l) {
                    return i * 100;
                }
            }).add({
                targets: '.dotted-line-vert',
                height: ['0px', '86px'],
                duration: 500,
                easing: 'linear',
                offset: '-=1500',
                delay: function delay(el, i, l) {
                    return i * 100;
                }
            }).add({
                targets: '.dotted-line-vert-left',
                height: ['0px', '110px'],
                duration: 500,
                easing: 'linear',
                offset: '-=1500'
            });
        },

        offset: '100%'
    });
    var waypoint2 = new Waypoint({
        element: document.getElementById('promotion'),
        handler: function handler(direction) {
            
            this.destroy();
            var timelinePromote = anime.timeline();
            timelinePromote.add({
                targets: '.line-prom-right',
                width: ['0', '900'],
                duration: 300,
                elasticity: 0,
                easing: 'easeInQuad'
            }).add({
                targets: ['#dot-5'],
                opacity: [0, 1],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=200'
            }).add({
                targets: ['#dot-5'],
                borderWidth: ['0px', '3px'],
                boxShadow: ['0px 0px 0 0px rgba(255, 247, 148, 0.45)', '0px 0px 0 4px rgba(255, 247, 148, 0.45)'],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=300'
            }).add({
                targets: ['.title-3_an'],
                opacity: ['0', '1'],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=300'
            }).add({
                targets: '.promo-block',
                opacity: ['0.01', '1'],
                scale: ['3', '1'],
                duration: 1500,
                easing: 'easeInOutBack',
                offset: '-=500',
                delay: function delay(el, i, l) {
                    return i * 200;
                }
            }).add({
                targets: '.promo-btn_an',
                opacity: ['0.01', '1'],
                scale: ['0.8', '1'],
                translateY: ['100', '0'],
                duration: 500,
                easing: 'easeInOutBack',
                offset: '-=500'
            }).add({
                targets: ['.promo-hor', '.promo-vert'],
                opacity: ['0.01', '1'],
                scale: ['0.5', '1'],
                duration: 500,
                easing: 'easeInOutBack',
                offset: '-=200'
            });
        },

        offset: '100%'
    });
    var waypoint3 = new Waypoint({
        element: document.getElementById('res-block'),
        handler: function handler(direction) {
            this.destroy();
            var timelineRes = anime.timeline();
            timelineRes.add({
                targets: '.res-block',
                maxHeight: [{
                    value: 0,
                    duration: 0,
                    delay: 0,
                    elasticity: 200
                }, {
                    value: 800,
                    duration: 1200,
                    delay: 0,
                    elasticity: 100,
                    easing: 'easeInQuart'
                }],
                duration: 1000,
                elasticity: 300,
                delay: 1000
            }).add({
                targets: '.res-img',
                translateX: ['-500px', '0px'],
                opacity: ['0.01', '1'],
                duration: 1500,
                elasticity: 300,
                offset: '-=900',
                easing: 'linear'
            }).add({
                targets: '.res-item',
                backgroundColor: [{
                    value: 'rgba(255, 255, 255, 1)',
                    duration: 0,
                    delay: 1000,
                    elasticity: 200
                }, {
                    value: 'rgba(255, 255, 255, 0)',
                    duration: 1200,
                    delay: function delay(el, i, l) {
                        return i * 500;
                    },
                    elasticity: 100,
                    easing: 'easeInQuad'
                }],
                translateX: ['2000px', '0px'],
                duration: 1500,
                elasticity: 300,
                offset: '-=2400',
                easing: 'easeInQuad',
                delay: function delay(el, i, l) {
                    return i * 300;
                }
            });
        },

        offset: '100%'
    });
    var waypoint4 = new Waypoint({
        element: document.getElementById('firstyle'),
        handler: function handler(direction) {
            this.destroy();
            var timelineRes = anime.timeline();
            timelineRes.add({
                targets: '.line-fir-right',
                width: ['0', '1000'],
                duration: 500,
                elasticity: 0,
                easing: 'easeInQuad'
            }).add({
                targets: ['#dot-6'],
                opacity: [0, 1],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=400'
            }).add({
                targets: ['#dot-6'],
                borderWidth: ['0px', '3px'],
                boxShadow: ['0px 0px 0 0px rgba(255, 247, 148, 0.45)', '0px 0px 0 4px rgba(255, 247, 148, 0.45)'],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=300'
            }).add({
                targets: ['.title-4_an'],
                opacity: ['0', '1'],
                duration: 500,
                easing: 'easeOutExpo',
                offset: '-=300'
            });
        },

        offset: '50%'
    });
    var waypoint5 = new Waypoint({
        element: document.getElementById('logo-slider'),
        handler: function handler(direction) {
            this.destroy();
            var timelineLogo = anime.timeline();
            timelineLogo.add({
                targets: '.logo-info_an',
                translateX: ['-1000', '0'],
                opacity: [0, 1],
                duration: 1000,
                elasticity: 0,
                easing: 'easeInQuad'
            }).add({
                targets: ['#logo-slider .tns-outer'],
                opacity: [0, 1],
                translateX: ['100%', '30%'],
                duration: 1000,
                easing: 'easeInQuad',
                offset: '-=2000'
            }); /* .add({
                  targets: '.logo-slider',
                  translateX: ['-20%', '-40%'],
                  duration: 2000,
                  offset: '-=1000',
                  easing: 'easeOutQuad',
                }) */
        },

        offset: '-10%'
    });
    var waypoint6 = new Waypoint({
        element: document.getElementById('des-slider'),
        handler: function handler(direction) {
            this.destroy();
            var timelineDes = anime.timeline();
            timelineDes.add({
                targets: '.des-info_an',
                translateX: ['1000', '0'],
                opacity: [0, 1],
                duration: 1000,
                elasticity: 0,
                easing: 'easeInQuad'
            }).add({
                targets: ['#des-slider .tns-outer'],
                opacity: [0, 1],
                translateX: ['-100%', '-29%'],
                duration: 1000,
                easing: 'easeInQuad',
                offset: '-=1000'
            }); /* .add({
                  targets: '.des-slider',
                  translateX: ['-60%', '-40%'],
                  duration: 2000,
                  offset: '-=1000',
                  easing: 'easeOutQuad',
                }) */
        },

        offset: '-10%'
    });
    var waypoint7 = new Waypoint({
        element: document.getElementById('pos-slider'),
        handler: function handler(direction) {
            this.destroy();
            var timelineLogo = anime.timeline();
            timelineLogo.add({
                targets: '.pos-info_an',
                translateX: ['-1000', '0'],
                opacity: [0, 1],
                duration: 1000,
                elasticity: 0,
                easing: 'easeInQuad'
            }).add({
                targets: ['#pos-slider .tns-outer'],
                opacity: [0, 1],
                translateX: ['100%', '30%'],
                duration: 1000,
                easing: 'easeInQuad',
                offset: '-=1000'
            }); /* .add({
                  targets: '.pos-slider',
                  translateX: ['-20%', '-40%'],
                  duration: 2000,
                  offset: '-=1000',
                  easing: 'easeOutQuad',
                }) */
        },

        offset: '-10%'
    });
    var waypoint8 = new Waypoint({
        element: document.getElementById('art-slider'),
        handler: function handler(direction) {
            this.destroy();
            var timelineDes = anime.timeline();
            timelineDes.add({
                targets: '.art-info_an',
                translateX: ['1000', '0'],
                opacity: [0, 1],
                duration: 1000,
                elasticity: 0,
                easing: 'easeInQuad'
            }).add({
                targets: ['#art-slider .tns-outer'],
                opacity: [0, 1],
                translateX: ['-100%', '-29%'],
                duration: 1000,
                easing: 'easeInQuad',
                offset: '-=1000'
            }); /* .add({
                  targets: '.art-slider',
                  translateX: ['-60%', '-40%'],
                  duration: 2000,
                  offset: '-=1000',
                  easing: 'easeOutQuad',
                }) */
        },

        offset: '-10%'
    });
}
});